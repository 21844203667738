import { createStore } from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import settings from './modules/settings'
import getters from './getters'
import variety from './modules/variety'
import inOrder from './modules/inOrder'
import outOrder from './modules/outOrder'
import { SET_VIEW_SIZE } from './consts';

const store = createStore({
  modules: {
    app,
    user,
    tagsView,
    permission,
    settings,
    variety,
    inOrder,
    outOrder,
    width: 1920,
    height: 1080,
    scale: 1,
    scalex: 1,
    scaley: 1
  },
  getters,
  mutations: {
    [SET_VIEW_SIZE](state, playload) {
      state.width = playload.width;
      state.height = playload.height;
      state.scale = playload.scale;
      state.scalex = playload.scalex;
      state.scaley = playload.scaley;
      console.log('viewSize', playload)
    },
  }
})

export default store
