import request from "@/utils/request";

// 查询入库预约单列表
export function listAppointment(query) {
  return request({
    url: "/wms/appointment/list",
    method: "get",
    params: query,
  });
}

// 查询入库预约单详细
export function getAppointment(id) {
  return request({
    url: "/wms/appointment/getInfo/" + id,
    method: "get",
  });
}

// 打印详细
export function getPrint(id) {
  return request({
    url: "/wms/appointment/print/" + id,
    method: "get",
  });
}

// 新增入库预约单
export function addAppointment(data) {
  return request({
    url: "/wms/appointment",
    method: "post",
    data: data,
  });
}

// 修改入库预约单
export function updateAppointment(data) {
  return request({
    url: "/wms/appointment",
    method: "put",
    data: data,
  });
}

// 仓库审核入库预约单
export function warehouseAudit(data) {
  return request({
    url: "/wms/appointment/warehouseAudit",
    method: "post",
    data: data,
  });
}

// 市场审核入库预约单
export function marketAudit(data) {
  return request({
    url: "/wms/appointment/marketAudit",
    method: "post",
    data: data,
  });
}

// 终止入库预约单
export function finish(data) {
  return request({
    url: "/wms/appointment/finish",
    method: "post",
    data: data,
  });
}

// 删除入库预约单
export function delAppointment(id) {
  return request({
    url: "/wms/appointment/" + id,
    method: "delete",
  });
}
// 查询入库预约单预约码
export function getAppointmentSign(id) {
  return request({
    url: "/wms/appointment/getSign/" + id,
    method: "get",
  });
}

// 仓库列表
export function houseList(query) {
  return request({
    url: "/wms/house/list",
    method: "get",
    params: query,
  });
}
// 预约码查询预约单号
export function getInfoBySign(appointmentSign) {
  return request({
    url: '/wms/appointment/getInfoBySign/' + appointmentSign,
    method: 'get',
  })
}

// 磅单入库
export function saveTruckPoundAndStack(data) {
  return request({
    url: "/wms/appointment/saveTruckPoundAndStack ",
    method: "post",
    data: data,
  });
}


export function selectCloudByCompanyProductStack(data) {
  return request({
    url: "/wms/stock/selectCloudByCompanyProductStack",
    method: "get",
    params: data
  });
}
export function firmOfferStock(data) {
  return request({
    url: "/wms/stock/firmOfferStock",
    method: "post",
    data: data,
  });
}




export function listPlateNosForDate(data) {
  return request({
    url: "/wms/truckType/listPlateNosForDate",
    method: "get",
    params: data
  });
}

// 根据入库时间和车牌查询物联网抓拍
export function getThings(data) {
  console.log(data)
  return request({
    url: "/wms/truckType/plateNos",
    method: "get",
    params: data
  });
}
// 根据入库时间和车牌查询物联网抓拍
export function plateNosNew(data) {
  console.log(data)
  return request({
    url: "/wms/truckType/plateNosNew",
    method: "get",
    params: data
  });
}
// 根据uuid查询微信已上传的凭证
export function queryUpload(data) {
  console.log(data)
  return request({
    url: "/wms/common/data/queryUpload",
    method: "post",
    data: data
  });
}
// 查询出库单车辆信息列表
export function listTruck(query) {
  return request({
    url: '/wms/inAppointmentTruck/list',
    method: 'get',
    params: query
  })
}
// 通过车牌查询司机登记信息
export function queryByNumber(data) {
  return request({
    url: '/wms/inAppointmentTruck/queryByNumber',
    method: 'post',
    data: data
  })
}
// 车辆登记
export function saveVehicleRegistration(data) {
  return request({
    url: '/wms/inAppointmentTruck/saveVehicleRegistration',
    method: 'post',
    data: data
  })
}

// 新增入库预约单
export function aiImportData(data) {
  return request({
    url: "/wms/appointment/aiImportData",
    method: "post",
    data: data,
  });
}
