<template>
  <el-dialog title="AI智能客服" v-model="dialogVisible" width="90%" @close="dialogVisible = false">
    <div class="common-layout" style="height:calc(80vh)">
      <el-scrollbar style="height: 85%;" ref="messagesContainer">
        <div v-for="item in form.msgList" :key="item.request_id">
          <div v-if="item.question">
            <div class="user-message">
              <div class="user-message-content">{{ item.question }}</div>
            </div>
          </div>
          <div v-if="item.answer || item.thought" class="bot-avatar">
            <img :src="botAvatar" alt="Bot Avatar" />
            <span style="font-size: 16px;font-weight: 700;">AI智能客服</span>
          </div>
          <div v-if="item.thought" class="thought-card">
            <div class="thinking-content">
              <vue3-markdown-it :source="item.thought" />
            </div>
          </div>
          <div v-if="item.answer" style="padding-left: 20px;">
            <div class="el-card__body">
              <vue3-markdown-it :source="item.answer" />
            </div>
          </div>
        </div>
      </el-scrollbar>
      <el-row
        style="position: absolute; width: 80%;bottom: 20px;left:50%;transform: translateX(-50%);align-items: center;">
        <div style="width: 100%;">
          <el-input type="textarea" style="float: left;width: 90%;" @keyup.enter="handleSend"
            v-model="form.input"></el-input>
          <el-button @click="handleSend" type="primary" :disabled="isSending"
            style="float: right; height: 36px;line-height: 42px;">{{ isSending ? '回答中' : '发送' }}</el-button>
        </div>
        <div style="margin: 0 auto;">
          <p style="color: red;font-size: 11px;">
            服务生成的所有内容均由人工智能模型生成，其生成内容的准确性和完整性无法保证，不代表我们的态度或观点
          </p>
        </div>
      </el-row>
    </div>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue'
import { ElMessage } from 'element-plus'
import Vue3MarkdownIt from 'vue3-markdown-it'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { v4 as uuidv4 } from 'uuid'
import botAvatar from '@/assets/logo/ai.png'
import store from '@/store'
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:modelValue'])
const dialogVisible = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
const form = reactive({
  input: '',//输入
  msgList: []
});
// 配置参数
const SSE_CONFIG = {
  url: 'https://wss.lke.cloud.tencent.com/v1/qbot/chat/sse',
  appKey: 'eppwHQgZ',
  sessionId: 'AKIDe5JJ5e0EF2hBRUJuISdZNzOaYIp8EZml'
}

// 响应式数据
const inputMessage = ref('')
const isSending = ref(false)
const messages = ref([])
const messagesContainer = ref()
let sseController = null

// SSE消息处理
const assembleMessage = (newMsg, type) => {
  if (type === 'QUESTION') {
    form.msgList.push({
      request_id: newMsg.request_id,
      question: newMsg.content
    })
    // console.log('QUESTION', form.msgList[form.msgList.length - 1], newMsg)
    return
  } else if (type === 'thought') {
    const index = form.msgList.findIndex(m => m.request_id === newMsg.request_id)
    if (index > -1) {
      form.msgList[index].thought = newMsg.procedures[0].debugging.content
    }
  } else if (type === 'ANSWER') {
    const index = form.msgList.findIndex(m => m.request_id === newMsg.request_id)
    if (index > -1 && newMsg.content != form.msgList[index].question) {
      form.msgList[index].answer = newMsg.content
    }
    // console.log('ANSWER', form.msgList[index], newMsg)
  }
  scrollToBottom()
  saveHistory()
}
const saveHistory = () => {
  localStorage.setItem('chatHistory' + store.getters.whCode, JSON.stringify(form.msgList))
}
// 初始化聊天记录
const initChatHistory = () => {
  const history = localStorage.getItem('chatHistory' + store.getters.whCode)
  if (history) {
    form.msgList = JSON.parse(history)
    scrollToBottom()
  } else {
    form.msgList = [{
      answer: `您好！欢迎使用智慧仓储平台智能客服助手。

我能为您提供以下帮助：

处理订单异常：快速定位订单问题（如延迟、数据错误等），提供解决方案。
优化仓储流程：根据您的需求，建议库存布局调整、拣货路线优化等策略。
系统操作指导：分步骤解答“如何创建订单”“管理库存”等操作问题。
我的特点：
✅ 回答严谨，确保信息准确
✅ 语言简单，拒绝复杂术语

请直接描述您的需求，我会全力协助！`}]
  }
}
onMounted(() => {
  initChatHistory()
  console.log('onMounted', store.getters)
})
// 添加定期清理逻辑
const MAX_HISTORY_ITEMS = 50 // 保留最近50条记录

watch(() => form.msgList.length, (newVal) => {
  if (newVal > MAX_HISTORY_ITEMS) {
    form.msgList = form.msgList.slice(-MAX_HISTORY_ITEMS)
    saveHistory()
  }
})
// 发送消息处理
const handleSend = async () => {
  if (!form.input.trim()) {
    ElMessage.warning('请输入有效内容')
    return
  }
  if (isSending.value) {
    ElMessage.warning('AI智能客服回答中...')
    return
  }
  try {
    isSending.value = true
    const requestId = uuidv4()

    // 添加用户消息
    assembleMessage({
      request_id: requestId,
      content: form.input.trim(),
      is_loading: true
    }, 'QUESTION')
    // 发起SSE请求
    sseController = new AbortController()

    await fetchEventSource(SSE_CONFIG.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        request_id: requestId,
        content: form.input.trim(),
        bot_app_key: SSE_CONFIG.appKey,
        session_id: SSE_CONFIG.sessionId,
        visitor_biz_id: store.getters.whCode,
        visitor_labels: []
      }),
      signal: sseController.signal,
      onopen: async (response) => {
        if (response.ok) return
        throw new Error(`SSE连接失败: ${response.status}`)
      },
      onmessage: (event) => {

        const data = JSON.parse(event.data)
        if (data.type === 'reply') {
          assembleMessage(data.payload, 'ANSWER')
        } else if (data.type === 'thought') {
          assembleMessage(data.payload, 'thought')
        }
      },

      onerror: (err) => {
        throw new Error(`SSE连接错误: ${err.message}`)
      }

    })
    form.input = ''
  } catch (error) {
    ElMessage.error(`发送失败: ${error.message}`)
    form.msgList.pop() // 移除加载中的消息
  } finally {
    isSending.value = false
  }
}

// 停止生成
const stopGeneration = () => {
  if (sseController) {
    sseController.abort()
    const lastMsg = form.msgList[form.msgList.length - 1]
    if (lastMsg) {
      lastMsg.content += '<span class="stop-ws">| 已停止生成</span>'
      lastMsg.is_final = true
    }
  }
}

// 自动滚动到底部
const scrollToBottom = () => {
  nextTick(() => {
    const scrollbar = messagesContainer.value
    if (scrollbar) scrollbar.setScrollTop(scrollbar.wrap$.scrollHeight)
  })
}

</script>

<style scoped>
.chat-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background: #f5f7fa;
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* 增加消息间距 */
}

/* 用户消息（居右） */
.user-message {
  justify-content: flex-end;
  margin-bottom: 16px;
  padding-bottom: 32px;
  display: flex;
  padding-right: 20px;
}

.user-message-content {
  color: #262626;
  padding: 20px;
  box-sizing: border-box;
  white-space: pre-wrap;
  word-break: break-word;
  background-color: #eff6ff;
  border-radius: 14px;
  max-width: calc(100% - 48px);
  position: relative;

}

/* 机器人消息（居左） */





/* 调整头像位置 */
.bot-avatar {
  margin-top: 8px;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.bot-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

/* 输入区域调整 */
.input-area {
  padding: 20px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 12px;
  position: sticky;
  bottom: 0;
}

/* 调整代码块显示 */
.markdown-content :deep(pre) {
  background: rgba(0, 0, 0, 0.05);
  padding: 12px;
  border-radius: 6px;
  overflow-x: auto;
}


.input-area {
  padding: 20px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.05);
  display: flex;
  gap: 12px;
}

.send-button {
  align-self: flex-end;
  height: 40px;
}

.loading-text {
  color: #909399;
  padding: 12px;
}

/* 新增样式 */
.thinking-message {
  margin-bottom: 8px;
  opacity: 0.8;
}

.thought-card {
  padding-left: 30px;
}

.thinking-content {
  display: flex;
  align-items: center;
  color: #6c757d;
  background: #f8f9fa !important;
  border-radius: 8px !important;
  padding: 10px;
  margin-right: 20px;

  i {
    margin-right: 8px;
    font-size: 16px;
    color: #adb5bd;
  }

  :deep(p) {
    margin: 0;
    font-size: 13px;
    line-height: 1.4;
  }
}

/* 调整正式回答的间距 */
.bot-card {
  margin-top: 8px;
}
</style>