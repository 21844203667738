<template>
  <div class="sys-datav" style="width:100%;height:100%;">
    <div :style="{ width: viewWidth, transform: viewTransformX }">
      <img src="@/assets/datav/title3.png" class="sys-datav-header" />
      <span class="sys-datav-title">监控数字化视频管控平台</span>
    </div>
    <div class="sys-datav-content">
      <router-view />
    </div>
    <div class="sys-datav-footer" :style="{ width: viewWidth, transform: viewTransformX2 ? viewTransformX2 : '' }">
      <datav-footer> </datav-footer>
    </div>
  </div>
</template>
<script setup >
import { reactive, getCurrentInstance, toRefs, watch, ref } from 'vue'
import DatavFooter from '@/layout/DatavFooter'
import { useCalcView } from '@/hooks/resize';
import emitter from '@/mitt';
import { useStore } from 'vuex'
import { SystemEvent } from '@/mitt/eventType';
import {
  resetUserPwd,
} from '@/api/system/user'
import { SET_VIEW_SIZE } from '@/store/consts';

const store = useStore()
const { proxy } = getCurrentInstance()
function onViewResize(w, h, s, viewW, viewH, viewS, viewTransformX) {
  const data = { width: viewW, height: viewH, scale: viewS };
  console.log(data)
  store.commit(SET_VIEW_SIZE, data);
  emitter.emit(SystemEvent.Resize, data);
}

const { viewWidth, viewHeight, viewTransform, viewTransformX, viewTransformY, viewTransformX2 } = useCalcView(onViewResize);
function handleResetPwd(userName, userId) {
  proxy
    .$prompt('原密码强度过低，请修改"' + userName + '"的密码', '警告', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      closeOnClickModal: false,
      inputPattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&().,/';:""'-=#])[A-Za-z\d$@$!%*?&().,/';:""'-=#]{12,}/,
      inputErrorMessage: '请输入包含英文字母大小写，数字和特殊符号的12位以上密码'
    })
    .then(({ value }) => {
      resetUserPwd(userId, value).then((response) => {
        proxy.$modal.msgSuccess('编辑成功，新密码是：' + value)
        setTimeout(() => {
          store.dispatch(" LogOut").then(() => {
            location.href = "/index"
          })
        }, 2000)
      })
    })
    .catch(() => { })
}

watch(
  () => store.state.user.passwordStatus,
  (value) => {
    console.log('passwordStatus', value)
    if (typeof value !== 'undefined' && value !== null && value === false) {
      console.log('handleResetPwd', value)
      // dialogVisible.value = true
      handleResetPwd(store.state.user.name, store.state.user.userId)
    }
  },
  {
    immediate: true,
  }
)
</script>
<style></style>
