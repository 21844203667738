import request from "@/utils/request";

// 查询出库预约单列表
export function listOutAppointment(query) {
  return request({
    url: "/wms/outAppointment/list",
    method: "get",
    params: query,
  });
}


// 查询出库预约单详细
export function getOutAppointment(id) {
  return request({
    url: "/wms/outAppointment/getInfo/" + id,
    method: "get",
  });
}

// 新增出库预约单
export function addOutAppointment(data) {
  return request({
    url: "/wms/outAppointment",
    method: "post",
    data: data,
  });
}

// 修改出库预约单
export function updateOutAppointment(data) {
  return request({
    url: "/wms/outAppointment",
    method: "put",
    data: data,
  });
}
// 无磅和无人出库预约单编辑保存（新）
export function editFinal(data) {
  return request({
    url: "/wms/outAppointment/editFinal",
    method: "put",
    data: data,
  });
}
// 有磅出库预约单编辑保存（新）
export function saveTruckPoundAndStackFinal(data) {
  return request({
    url: "/wms/outAppointment/saveTruckPoundAndStackFinal",
    method: "post",
    data: data,
  });
}
// 仓库审核出库预约单
export function warehouseAudit(data) {
  return request({
    url: "/wms/outAppointment/warehouseAudit",
    method: "post",
    data: data,
  });
}

// 市场审核出库预约单
export function marketAudit(data) {
  return request({
    url: "/wms/outAppointment/marketAudit",
    method: "post",
    data: data,
  });
}
// 仓库审核出库预约单(新)
export function warehouseAuditNew(data) {
  return request({
    url: "/wms/outAppointment/warehouseAuditNew",
    method: "post",
    data: data,
  });
}

// 市场审核出库预约单(新)
export function marketAuditNew(data) {
  return request({
    url: "/wms/outAppointment/marketAuditNew",
    method: "post",
    data: data,
  });
}
// 仓库审核出库预约单(通用)
export function warehouseAuditFinal(data) {
  return request({
    url: "/wms/outAppointment/warehouseAuditFinal",
    method: "post",
    data: data,
  });
}

// 市场审核出库预约单（通用）
export function marketAuditFinal(data) {
  return request({
    url: "/wms/outAppointment/marketAuditFinal",
    method: "post",
    data: data,
  });
}
// 终止出库预约单
export function disableOutAppointment(data) {
  return request({
    url: "/wms/outAppointment/finish",
    method: "post",
    data: data,
  });
}
// 终止出库预约单(新)
export function finishFinal(data) {
  return request({
    url: "/wms/outAppointment/finishFinal",
    method: "post",
    data: data,
  });
}
// 终止出库预约单(有人)
export function disableOutAppointmentNew(data) {
  return request({
    url: "/wms/outAppointment/finishNew",
    method: "post",
    data: data,
  });
}
// 删除出库预约单
export function delOutAppointment(id) {
  return request({
    url: "/wms/outAppointment/" + id,
    method: "delete",
  });
}
// 打印详细
export function getPrint(id) {
  return request({
    url: "/wms/outAppointment/print/" + id,
    method: "get",
  });
}

// 查询出库预约单详细
export function getReceiptOutByAppointmentCode(appointmentCode) {
  return request({
    url: "/wms/receiptOut/getReceiptOutByAppointmentCode/" + appointmentCode,
    method: "get",
  });
}

// 预约码查询预约单号
export function getOutInfoBySign(appointmentSign) {
  return request({
    url: '/wms/outAppointment/getInfoBySign/' + appointmentSign,
    method: 'get',
  })
}

// 按客户、商品、垛位新增预约单接口
export function addOutAppointmentBatch(data) {
  return request({
    url: "/wms/outAppointment/addOutAppointmentBatch",
    method: "post",
    data: data,
  });
}
// 按客户、商品、垛位新增预约单接口(新)
export function saveOutAppointmentBatch(data) {
  return request({
    url: "/wms/outAppointment/saveOutAppointmentBatch",
    method: "post",
    data: data,
  });
}
// 按客户、商品、垛位新增预约单接口
export function addOutAppointmentBatchNew(data) {
  return request({
    url: "/wms/outAppointment/addOutAppointmentBatchNew",
    method: "post",
    data: data,
  });
}
// 编辑预约单接口
export function updateOutAppointmentNew(data) {
  return request({
    url: "/wms/outAppointment/editNew",
    method: "put",
    data: data,
  });
}
// 磅单出库
export function saveTruckPoundAndStack(data) {
  return request({
    url: "/wms/outAppointment/saveTruckPoundAndStack",
    method: "post",
    data: data,
  });
}
// 磅单出库(新)
export function saveTruckPoundAndStackNew(data) {
  return request({
    url: "/wms/outAppointment/saveTruckPoundAndStackNew",
    method: "post",
    data: data,
  });
}

/*出库凭证上传 
参数 id truckFile type 1入库 2出库  */
export function updateTruckFilePc(query) {
  return request({
    url: "/wms/outAppointment/updateTruckFilePc",
    method: 'get',
    params: query,
  });
}
/*最新出库垛位凭证获取
参数 id出库垛位ID  type 1入库预约单 2出库预约单 3入库单 4出库单 */
export function getTruckFile(query) {
  return request({
    url: "/wms/outAppointment/getTruckFile/" + query,
    method: 'get',
  });
}

/*出库预约单批量保存或出库
参数  "goodsCertificateFile":"提货凭证",
    "railwayFile":"铁路大票",
    "pipelineFile":"管道票据",
    "shipFile":"轮船票据",
    "ids":[39880,39879],
    "type": "1为保存并出库"*/
export function updateOutAppointmentBatch(data) {
  return request({
    url: "/wms/outAppointment/updateOutAppointmentBatch/",
    method: 'post',
    data
  });
}
// 查询出库单车辆信息列表
export function listTruck(query) {
  return request({
    url: '/wms/outAppointment/listTruck',
    method: 'get',
    params: query
  })
}
// AI图片识别批量导入预约单
export function aiImportData(data) {
  return request({
    url: "/wms/outAppointment/aiImportData",
    method: "post",
    data: data,
  });
}