
export const ShapeEvent = Object.freeze({
    EditShape: 'editShape', //属性面板修改  绘图shape监听事件
    ShapeMove: 'shapeMove', //shape移动  属性面板修改事件
    CottonChangeProp: "CottonChangeProp",//主面板监听事件
    AreaChangeProp: "AreaChangeProp",//库区板监听事件
    RoomChangeProp: "RoomChangeProp",//库房监听事件
    StackChangeProp: "StackChangeProp",//库房监听事件
    DeviceChangeProp: "DeviceChangeProp",//设备面板监听事件
    InnerEditSwith: "InnerEditSwith",//剁位细节编辑弹窗开关
    RoomEditData: "EditRoomData",//剁位细节编辑数据
    RoomEditComplete: "RoomEditComplete",//剁位信息编辑完成
    AutoNest: "AutoNest",//自动排版
    DeviceRemove: "deviceRemove"//设备删除
});
export const MapDataChangeEvent = Object.freeze({
    // 参数传递  data:[{longitude:number,latitude:number,type}]
    DataChange: 'data-change',
    // 参数传递  data:[{longitude:number,latitude:number,type}],inout：boolean //流向
    GoodsToDataChange: 'goodsto-data-change',
    IsSelecting: 'is-selecting',
    mapTagChange: 'map-tag-change',
    ChangeProvince: 'change-province',
    ChangeArea: 'change-area',
    ClickProvince: 'click-province',
    ClickArea: 'click-area',
    ChangeWarehouseVolume: 'change-WarehouseVolume ',
    MapSearchChange: 'mapSearchChange ',
    mapLoadOK: 'mapLoadOK',
    mapLoadOK2: 'mapLoadOK2'
});
export const SystemEvent = Object.freeze({
    // 传递数据  {width:number,height:number }
    Resize: 'resize',
    Render3DAfter: 'render3d-after'
});
export const ChooseInChangeEvent = Object.freeze({
    ToogleChooseIn: 'toogleChooseIn'
});