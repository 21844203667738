<template>
  <div v-if="!item.hidden">
    <template v-if="hasOneShowingChild(item.children, item) &&
      (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
      !item.alwaysShow
      ">
      <app-link v-if="sidebarRouters[activeName] && sidebarRouters[activeName].meta" :to="resolvePath(item.fullPath)">
        <el-menu-item :index="setIndex(item.fullPath)" @click="setOpenKey(item.fullPath, item)"
          :class="{ 'submenu-title-noDropdown': !isNest }">
          <svg-icon v-if="!isNest" style="font-size: 22px" :icon-class="onlyOneChild.meta.icon || (item.meta && item.meta.icon)
            " />
          <div v-else style="width: 10px"></div>
          <template #title><span class="menu-title" :title="hasTitle(onlyOneChild.meta.title)">{{ onlyOneChild.meta.title
          }}</span></template>
        </el-menu-item>
      </app-link>
    </template>

    <el-sub-menu v-else ref="subMenu" :index="resolvePath(item.fullPath)" style="--el-menu-item-height: 44px"
      popper-append-to-body>
      <template v-if="item.meta" #title>
        <svg-icon :icon-class="item.meta && item.meta.icon" style="font-size: 22px" />
        <span class="menu-title" :title="hasTitle(item.meta.title)">{{
          item.meta.title
        }}</span>
      </template>
      <sidebar-item v-for="child in item.children" :key="child.path" :is-nest="true" :item="child"
        :base-path="resolvePath(child.fullPath)" class="nest-menu" />
    </el-sub-menu>
  </div>
</template>

<script setup>
const store = useStore()
import { isExternal } from "@/utils/validate"
import AppLink from "./Link"
const sidebarRouters = computed(() =>
  store.getters.sidebarRouters.filter((item) => !item.hidden)
)

const props = defineProps({
  // route object
  item: {
    type: Object,
    required: true,
  },
  isNest: {
    type: Boolean,
    default: false,
  },
  basePath: {
    type: String,
    default: "",
  },
  activeName: {
    type: Number,
    default: 0,
  },
})

const onlyOneChild = ref({})
function hasOneShowingChild(children = [], parent) {
  if (!children) {
    children = []
  }
  const showingChildren = children.filter((item) => {
    if (item.hidden) {
      return false
    } else {
      onlyOneChild.value = item
      return true
    }
  })
  if (showingChildren.length === 1) {
    return true
  }
  if (showingChildren.length === 0) {
    onlyOneChild.value = { ...parent, path: "", noShowingChildren: true }
    return true
  }
  return false
}

function resolvePath(routePath, routeQuery) {
  if (isExternal(routePath)) {
    return routePath
  }
  if (isExternal(props.basePath)) {
    return props.basePath
  }
  // if (routeQuery) {
  //   let query = JSON.parse(routeQuery)
  //   return {
  //     path: getNormalPath(props.basePath + "/" + routePath),
  //     query: query,
  //   }
  // }
  return routePath ? routePath : '/'
  // return getNormalPath( routePath)
}
const setIndex = (path) => {
  if (path) {
    return path.substring(0, path.lastIndexOf('/'))
  } else {
    return 'index'
  }
}
const setOpenKey = (path, activeName) => {
  localStorage.setItem('openKey', path ? path.substring(0, path.lastIndexOf('/')) : '/index')
}
function hasTitle(title) {
  if (title.length > 5) {
    return title
  } else {
    return ""
  }
}
</script>

<style lang="scss">
.el-sub-menu .el-sub-menu__icon-arrow {
  right: 5px;
  color: rgba(0, 0, 0, .85);
}

.el-menu-item {
  height: 44px !important;
  line-height: 44px !important;
  font-size: 14px;
  padding: 0 12px !important;
}

.el-sub-menu__title {
  padding: 0 12px !important;
}

.router-link-active {
  color: #337FFF;
  background: #F0F5FF;

  .svg-icon,
  .menu-title {
    color: #337FFF !important;
  }
}

.el-sub-menu__title:hover {
  border-radius: 8px 0 0 8px;
  background: #f00;
}
</style>
