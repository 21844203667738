<template>
  <el-form ref="formRef" :model="form" :rules="rules" label-width="80px" @validate="handleFormValidate">
    <el-table :data="form.tableData" :border="border" :row-key="getRowKey" v-loading="loading" lazy max-height="550">
      <el-table-column type="index" width="55" label="序号">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].index`" label-width="0" :style="elFormItemClass">
            {{ $index + 1 }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="wbCode" label="磅单编号" width="150" v-if="scene === 'storage'">
        <template #default="{ row, $index }">
          <el-form-item label-width="0" :prop="`tableData[${$index}].wbCode`" :style="elFormItemClass">
            {{ row.wbCode }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="weighbridgeWeight" label="磅重" width="120" v-if="scene === 'storage'">
        <template #default="{ row, $index }">
          <el-form-item label-width="0" :prop="`tableData[${$index}].weighbridgeWeight`" :style="elFormItemClass">
            {{ row.weighbridgeWeight }}
            <dict-tag :options="capacity_unit" :value="row.weightUnit" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="roomName" label="库房" show-overflow-tooltip min-width="120" v-if="scene !== 'storage'">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].roomName`" label-width="0" :style="elFormItemClass">
            {{ row.roomName }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="stackName" show-overflow-tooltip min-width="150">
        <template #header>
          <div :class="scene !== 'add' ? 'cell-item setred' : 'cell-item'">垛位</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].stackName`" label-width="0" :style="elFormItemClass"
            :rules="scene === 'storage' ? rules.stackName : null">
            <StackSelect v-model="row.stackId" :stacks="stackList" @change="changeStack($event, row)" :isGetStack="false"
              :clearable="false" v-if="scene === 'storage' || scene === 'edit'" />
            <span v-else>{{ row.stackName }}</span>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="productName" v-if="isShowProduct" :label="varietyCode === 'CF' ? '批次号' : '商品名称'"
        show-overflow-tooltip>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].productName`" label-width="0" :style="elFormItemClass">
            {{ row.productName }}
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="wbOrderType === 'GOODS_IN'" prop="remainAmount" label="剩余库容" min-width="100">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].remainAmount`" label-width="0" :style="elFormItemClass">
            {{ row.remainAmount }}<dict-tag :options="capacity_unit" :value="row.weightUnit" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="wbOrderType === 'GOODS_OUT'" prop="availableCount,availableWeight" label="可用库存" width="170">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].availableCount`" label-width="0" :style="elFormItemClass">
            {{ row.availableWeight }}
            <dict-tag :options="capacity_unit" :value="row.weightUnit" />
            /
            {{ row.availableCount }}
            <dict-tag :options="capacity_unit" :value="row.countUnit" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="availableCount,availableWeight" label="可注销张数" v-if="isCancel && scene == 'add'">
        <template #default="scope">
          {{ parseInt(scope.row.remainAmount / futuresLotTon) }}张
        </template>
      </el-table-column>
      <el-table-column prop="receiptCount" label="注销张数" width="150" v-if="isCancel">
        <template #default="scope">
          <el-input v-model="scope.row.receiptCount" placeholder="请输入张数" clearable @change="getWeightCount(scope.row)"
            v-if="scene == 'add'" />
          <div v-else>
            {{ scope.row.receiptCount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="receiptCount" label="出库张数" width="150" v-if="isSheetsNumber">
        <template #default="scope">
          <el-input v-model="scope.row.receiptCount" placeholder="请输入张数" clearable @change="getWeightCount(scope.row)"
            v-if="scene == 'add'" />
          <div v-else>
            {{ scope.row.receiptCount }}
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="storageBasis === '2'" prop="totalWeight" min-width="180">
        <template #header>
          <div class="cell-item setred">{{ weightName }}</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].totalWeight`" label-width="0"
            :rules="(scene !== 'add' || row.isNeedValidate) ? rules.totalWeight : null" :style="elFormItemClass">
            <el-input v-model="row.totalWeight" placeholder="请输入重量"
              @input="handleInput(row, wbOrderType, scene, showWeight)">
              <template #append>
                <dict-tag :options="capacity_unit" :value="weightUnit" />
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column prop="totalCount" min-width="180">
        <template #header>
          <div class="cell-item setred">{{ countName }}</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].totalCount`" label-width="180px"
            :rules="(scene !== 'add' || row.isNeedValidate) ? rules.totalCount : null" :style="elFormItemClass">
            <div style="display: flex; align-items: center;">
              <el-input v-if="storageBasis === '2'" v-model="row.totalCount" placeholder="请输入数量" @input="oninput()">
                <template #append>
                  <dict-tag :options="capacity_unit" :value="countUnit" />
                </template>
              </el-input>
              <el-input v-else v-model="row.totalCount" placeholder="请输入数量" @input="handleInputCount(row)">
                <template #append>
                  <dict-tag :options="capacity_unit" :value="countUnit" />
                </template>
              </el-input>
              <!-- <span
                v-if="(scene === 'storage' || scene === 'edit' || (showWeight)) && row.totalCount > 0 && row.specificationVal > 0"
                style="margin-left: 10px;font-size: 12px; width: 100%; color: #666">规格重量:
                {{ multiply(row.totalCount, row.specificationVal) }} <dict-tag :options="capacity_unit"
                  :value="row.weightUnit" /></span> -->
            </div>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column v-if="storageBasis === '1'" prop="totalWeight" min-width="180">
        <template #header>
          <div class="cell-item setred">{{ weightName }}</div>
        </template>
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].totalWeight`" label-width="0"
            :rules="(scene !== 'add' || row.isNeedValidate) ? rules.totalWeight : null" :style="elFormItemClass">
            <el-input v-model="row.totalWeight" placeholder="请输入重量" @input="row.isNeedValidate = true; summation()">
              <template #append>
                <dict-tag :options="capacity_unit" :value="weightUnit" />
              </template>
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <template v-if="carVisible">
        <el-table-column prop="truckTime" width="160">
          <template #header>
            <!-- <div v-if="transWay === '5'" class="cell-item">{{ wbOrderType === 'GOODS_OUT' ? '车辆出库日期' : '车辆入库日期' }}</div> -->
            <div :class="scene === 'storage' ? 'cell-item' : 'cell-item setred'">{{ wbOrderType === 'GOODS_OUT' ? '车辆出库日期'
              : '车辆入库日期' }}</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].truckTime`"
              :rules="(scene !== 'add' || row.isNeedValidate) ? rules.truckTime : null" :style="elFormItemClass">
              <el-date-picker v-model="row.truckTime" type="date" value-format="YYYY-MM-DD HH:mm:ss" placeholder="请选择日期"
                style="width: 100%" :disabled-date="pickerOptions" v-if="scene !== 'storage'"
                @change="changeDate(row)"></el-date-picker>
              <span v-else>{{ row.truckTime || row.createTime }}</span>
            </el-form-item>
          </template>
        </el-table-column>

        <el-table-column prop="carPlateNum" width="140">
          <template #header>
            <!-- <div v-if="transWay === '5'" class="cell-item">车牌号</div> -->
            <div :class="scene === 'storage' ? 'cell-item' : 'cell-item setred'">车牌号</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].carPlateNum`"
              :rules="(scene !== 'add' || row.isNeedValidate) ? rules.carPlateNum : null" :style="elFormItemClass">
              <el-select v-model="row.carPlateNum" filterable allow-create placeholder="请选择车牌" @click="getCar(row)"
                @change="changeCar($event, row)" value-key="id" default-first-option v-if="scene !== 'storage'">
                <el-option v-for="item in cars" :key="item.id" :label="item.carPlateNum" :value="item">
                </el-option>
              </el-select>
              <span v-else>{{ row.carPlateNum }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="time" width="140" v-if="scene !== 'storage'">
          <template #header>
            <!-- <div v-if="transWay === '5'" class="cell-item">车牌号</div> -->
            <div :class="scene === 'storage' ? 'cell-item' : 'cell-item setred'">抓拍时间</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].time`"
              :rules="(scene !== 'add' || row.isNeedValidate) ? rules.time : null" :style="elFormItemClass">
              <el-select v-model="row.time" filterable allow-create placeholder="请选择抓拍时间" @click="getCarTime(row)"
                @change="changeTime($event, row)" value-key="id" default-first-option v-if="scene !== 'storage'">
                <el-option v-for="item in snapTimeList" :key="item.id" :label="item.time" :value="item">
                  <span style="float: left">{{ item.time }}</span>
                  <span style=" float: right;color: var(--el-text-color-secondary);font-size: 13px;padding-left: 20px;">{{
                    (item.isShow ? '已上传 ' : ' ') + (item.isExit !== 1 ? ' 入场抓拍' : ' 出场抓拍')
                  }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="wbCode" v-if="showWeight" width="100">
          <template #header>
            <div class="cell-item setred">关联榜单</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].wbCode`" :rules="rules.wbCode"
              :style="elFormItemClass">
              <el-link :disabled="!row.carPlateNum" type="primary" round @click="relevanceWB(row, $index)">{{
                row.wbCode ? '已关联榜单' : '选择磅单' }}</el-link>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="vehicleVoucher" :width="'115px'">
          <template #header>
            <!-- <div v-if="transWay === '5'" class="cell-item">车辆附件</div> -->
            <div :class="scene === 'add' ? 'cell-item' : 'cell-item setred'">车辆附件</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].vehicleVoucher`" :style="elFormItemClass"
              :rules="scene === 'edit' ? rules.vehicleVoucher : null">
              <div>
                <el-link type="primary" @click="showCarFile(row, $index)" style="margin-right: 5px;">{{
                  row.vehicleVoucher ? '查看'
                  : '上传' }}</el-link>
                <el-link type="primary" @click="showQrcode(row)"
                  v-if="isShowFile && row.id !== 0 && row.id !== ''">手机上传</el-link>
              </div>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="isThings" width="100">
          <template #header>
            <div class="cell-item">物联网</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item :prop="`tableData[${$index}].isThings`" :style="elFormItemClass">
              <el-button type="text" @click="chooseSnapshot(row)" v-if="scene === 'edit' && !showWeight">选择</el-button>
              <el-button type="text" @click="showNetwork(row)">查看</el-button>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="wbCertificateFile" width="100">
          <template #header>
            <div class="cell-item">磅单凭证</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].wbCertificateFile`" :style="elFormItemClass">
              <div>
                <el-link type="primary" @click="showGoodsFile(row, $index)">{{
                  row.wbCertificateFile ? '查看'
                  : '上传' }}</el-link>
              </div>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="driverName" label="司机名称" width="100">
          <template #default="{ row, $index }">
            <el-input v-model="row.driverName" placeholder="请输入" v-if="scene !== 'storage'" :style="elFormItemClass" />
            <span v-else>{{ row.driverName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="driverPhone" width="140">
          <template #header>
            <div class="cell-item">司机电话</div>
          </template>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].driverPhone`" :rules="rules.driverPhone"
              :style="elFormItemClass">
              <el-input v-model="row.driverPhone" placeholder="请输入" v-if="scene !== 'storage'" />
              <span v-else>{{ row.driverPhone }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="idCard" label="身份证号" width="180" show-overflow-tooltip>
          <template #default="{ row, $index }">
            <el-form-item label-width="0" :prop="`tableData[${$index}].idCard`" :rules="rules.idCard"
              :style="elFormItemClass">
              <el-input v-model="row.idCard" placeholder="请输入" v-if="scene !== 'storage'" />
              <span v-else>{{ row.idCard }}</span>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="wbCode" label="过场编号" show-overflow-tooltip min-width="180"
          v-if="form.tableData.length > 0 && props.scene === 'edit' && scene !== 'storage'">
          <!-- && wbCodeExist -->
          <template #default="{ row, $index }">
            <el-form-item :prop="`tableData[${$index}].wbCode`" label-width="0" :style="elFormItemClass">
              {{ row.wbCode }}
            </el-form-item>
          </template>
        </el-table-column>
      </template>
      <el-table-column prop="remark" label="备注" width="150" v-if="isShowRemark">
        <template #default="{ row, $index }">
          <el-form-item :prop="`tableData[${$index}].remark`" :style="elFormItemClass">
            <el-input v-model="row.remark" placeholder="请输入备注" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="110">
        <template #default="{ row, $index }">
          <el-link type="primary" @click="addRow(row)" v-if="scene !== 'add'">添加</el-link>
          &nbsp;&nbsp;
          <el-link type="primary" @click="removeRow($index, row)">移除</el-link>
          <!--            <el-link type="primary"-->
          <!--              v-if="wbOrderType === 'GOODS_OUT' && scene === 'storage' || (showWeight && wbOrderType === 'GOODS_OUT') || (scene === 'edit' && wbOrderType === 'GOODS_OUT')"-->
          <!--              @click="takeStock($index, row, productCode, companyCode)">-->
          <!--              库存盘点-->
          <!--            </el-link>-->
        </template>
      </el-table-column>
    </el-table>
  </el-form>
  <StatisticInfo class="statistic-static">
    <div class="statistic-item">合计：</div>
    <div v-if="storageBasis === '2'" class="statistic-item">
      总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="weightUnit" />
    </div>
    <div class="statistic-item">
      总数量：<span class="count">{{ totalCount.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="countUnit" />
    </div>
    <div v-if="storageBasis === '1'" class="statistic-item">
      总重量：<span class="count">{{ totalWeight.toFixed(4) }}</span>
      <dict-tag :options="capacity_unit" :value="weightUnit" />
    </div>
  </StatisticInfo>
  <!--物联网-->
  <OperationDetailsDialog v-model="thingsDialogVisible" :data="selectItem.thingsInfo" :snapType="true" />
  <el-dialog title="选择抓拍图片" v-model="chooseSnapshotVisible" :close-on-click-modal="false" width="80%" append-to-body
    destroy-on-close>
    <el-form ref="addGoodFile" :model="snapshotForm" label-width="130px">
      <el-form-item label="入场抓拍" prop="inFile">
        <div style="display: flex;flex-direction: column;gap: 20px;">
          <el-button class="blue-btn" @click="getNextDaySnapshot(-1)" style="width: 150px;">获取前一日入场抓拍</el-button>
          <choose ref="inFileRef" v-model="snapshotForm.inFileList" />
        </div>
      </el-form-item>
      <el-form-item label="出场抓拍" prop="outFile">
        <div style="display: flex;flex-direction: column;gap: 20px;">
          <el-button class="blue-btn" @click="getNextDaySnapshot(1)" style="width: 150px;">获取次日出场抓拍</el-button>
          <choose ref="outFileRef" v-model="snapshotForm.outFileList" />
        </div>
      </el-form-item>
      <el-form-item label="抓拍丢失备注" prop="outFile">
        <el-input v-model="snapshotForm.snapshotRemark" :maxlength="100" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button class="gray-btn" @click="chooseSnapshotVisible = false">取 消</el-button>
        <el-button class="blue-btn" @click="uploadHandler">确 定</el-button>
      </div>
    </template>
  </el-dialog>
  <car-file v-model:isShow="isShowCarFile" :vehicleVoucher="selectItem?.vehicleVoucher" :truckId="selectItem?.id + ''"
    :orderType="wbOrderType === 'GOODS_IN' ? '1' : '2'" @changeVv="changeVehicleVoucher" flag="updateTruckFilePc"
    :isSaveFile="scene !== 'add' && selectItem?.id !== 0 && selectItem?.id !== ''"></car-file>
  <goods-file v-model:isShow="isShowGoodsFile" :data="selectItem?.wbCertificateFile" @change="changeGoods"></goods-file>
  <!-- 生成二维码 -->
  <el-dialog :title="`请用微信扫描二维码上传凭证(ID:${selectItem ? selectItem.id : ''})`" v-model="updateCodeShow" width="400px"
    @close="getWXUploadFiles(selectItem.id)" append-to-body>
    <div style=" display: flex;flex-direction: column;align-items: center;">
      <qrcode-vue :value="qrcodeData" :size="250"></qrcode-vue>
      <div style="padding-top: 20px;">请上传完毕再关闭此页面</div>
    </div>
  </el-dialog>
  <!--选择磅单-->
  <SelectWeightList ref="selectWeightListRef" v-model="weightVisible" :wbOrderType="wbOrderType"
    :carPlateNum="selectItem.carPlateNum" :wbCode="selectItem.wbCode" @submit="sureSelectWB"
    @cancel="weightVisible = false" />
  <!--  <el-dialog v-model="stockVisible" title="库存盘点" width="60%" append-to-body>-->
  <!--    <el-table :data="stockData" :border="border" @selection-change="handleSelectionChange">-->
  <!--      <el-table-column type="selection" width="55"></el-table-column>-->
  <!--      <el-table-column label="序号" width="55">-->
  <!--        <template #default="scope">-->
  <!--          {{ scope.$index + 1 }}-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="cloudCode" label="云仓单号" width="160" />-->
  <!--      <el-table-column prop="productName" label="物品名称" show-overflow-tooltip />-->
  <!--      <el-table-column prop="specificationVal" label="规格" show-overflow-tooltip />-->
  <!--      <el-table-column prop="remainWeight" label="磅重(吨)" show-overflow-tooltip />-->
  <!--      <el-table-column label="实盘重量" width="120">-->
  <!--        <template #default="scope">-->
  <!--          <el-input v-model="scope.row.firmOfferWeight" type="number" placeholder="" @input="updateProfitLoss(scope.row)"-->
  <!--            @keypress="handleKeyPress"></el-input>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="weight" label="盈亏重量" show-overflow-tooltip />-->
  <!--      <el-table-column prop="remainCount" label="系统数量" show-overflow-tooltip />-->
  <!--      <el-table-column label="实盘数量" width="120">-->
  <!--        <template #default="scope">-->
  <!--          <el-input placeholder="" type="number" v-model="scope.row.firmOfferCount" @input="updateProfitLoss(scope.row)"-->
  <!--            @keypress="handleKeyPress"></el-input>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="count" label="盈亏数量" show-overflow-tooltip>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column prop="result" label="盘点结果" show-overflow-tooltip>-->
  <!--        <template #default="scope">-->
  <!--          <template v-if="scope.row.count < 0 && scope.row.varietyCode === 'CF'">-->
  <!--            <span style="color: red">盘亏</span>-->
  <!--          </template>-->
  <!--          <template v-if="scope.row.count >= 0 && scope.row.varietyCode === 'CF'">-->
  <!--            <span style="color: green">盘盈</span>-->
  <!--          </template>-->
  <!--          <template v-if="scope.row.weight < 0 && scope.row.varietyCode !== 'CF'">-->
  <!--            <span style="color: red">盘亏</span>-->
  <!--          </template>-->
  <!--          <template v-if="scope.row.weight >= 0 && scope.row.varietyCode !== 'CF'">-->
  <!--            <span style="color: green">盘盈</span>-->
  <!--          </template>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--      <el-table-column label="备注" width="120">-->
  <!--        <template #default="scope">-->
  <!--          <el-input v-model="scope.row.remark" placeholder="请输入备注"></el-input>-->
  <!--        </template>-->
  <!--      </el-table-column>-->
  <!--    </el-table>-->
  <!--    <div style="text-align: right;">-->
  <!--      <el-button type="primary" class="confirm-button" @click="confirmStockTaking">确定盘库</el-button>-->
  <!--    </div>-->
  <!--  </el-dialog>-->
</template>
<script setup name="indexEdit">
import { watch, onMounted } from 'vue'
import { Decimal } from 'decimal.js';
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import {
  firmOfferStock,
  listPlateNosForDate,
  selectCloudByCompanyProductStack,
  queryByNumber
} from '@/api/wms/appointment/inAppointment'
import { getCarPictureList } from '@/api/wms/carPicture/picture'
import { getTruckFile } from '@/api/wms/appointment/outAppointment'
import OperationDetailsDialog from '@/components/OperationDetailsDialog/ImagesDialog.vue'
import carFile from '@/views/wms/order/components/carFile.vue'
import goodsFile from '@/views/wms/order/components/goodsFile.vue'
import QrcodeVue from "qrcode.vue"
import SelectWeightList from '@/components/SelectWeightList/index.vue'
import StackSelect from '@/components/StackSelect'
import { getThings } from '@/api/wms/appointment/inAppointment'
import { ElMessageBox } from "element-plus"
import { getQrCodeUrl } from '@/utils/index'
import { listCompanyTruck } from '@/api/wms/companyTruck/companyTruck'
import choose from '@/components/UploadFile/choose.vue'
const store = useStore()
const { proxy } = getCurrentInstance()
const { capacity_unit } = proxy.useDict('capacity_unit')
const pickerOptions = (time) => {
  // 定义禁止选择的时间范围
  const now = new Date();
  const targetDate = new Date(time.getFullYear(), time.getMonth(), time.getDate());
  return targetDate >= now; // 禁用今天之前的日期
}

const props = defineProps({
  scene: {
    type: String,
    default: 'add'
  },
  data: {
    type: Array,
    default: () => {
      return []
    }
  },
  border: {
    type: Boolean,
    default: true
  },
  carVisible: {
    type: Boolean,
    default: false
  },
  isShowProduct: {
    type: Boolean,
    default: false
  },
  isShowRemark: {
    type: Boolean,
    default: false
  },
  countName: {
    type: String,
    default: '数量'
  },
  weightName: {
    type: String,
    default: '分配重量'
  },
  companyCode: {
    type: String,
    default: ''
  },
  productCode: {
    type: String,
    default: ''
  },
  wbOrderType: {
    type: String,
    default: 'GOODS_IN'
  },
  showWeight: {
    type: Boolean,
    default: false
  },
  isShowFile: {
    type: Boolean,
    default: false
  },
  stacks: {
    type: Array,
    default: []
  },
  isCancel: {
    type: Boolean,
    default: false
  },
  isSheetsNumber: {
    type: Boolean,
    default: false
  },
  transWay: {
    type: String,
    default: ''
  },
  sourceWay: {
    type: String,
    default: ''
  },
  outType: {
    type: String,
    default: ''
  }
})
const form = reactive({ tableData: [] })
const emit = defineEmits(['removeIndex', 'TableValidate', 'confirm-and-refresh', 'changeGoods', 'checkFormBatchOKHander'])
const totalCount = ref(0)
const totalWeight = ref(0)
const storageBasis = ref()
const weightUnit = ref()
const varietyCode = ref()
const countUnit = ref()
const inputChange = ref(true)
const reservation = (rule, value, callback) => {
  let reg = ''
  if (!props.outType || (props.outType === '2' && rule.field.indexOf('totalWeight') !== -1)
    || (props.outType === '1' && rule.field.indexOf('totalCount') !== -1)) {
    reg = /^(?!0(?:\.0{0,4})?$)\d*(?:\.\d{1,4})?$/
  } else {
    reg = /^0+(?:\.0{1,4})?$|^(?!0$)\d*(?:\.\d{1,4})?$/
  }
  if (reg.test(value)) {
    callback()
  } else {
    callback(new Error("请输入正确的格式"))
  }
}
const getRowKey = (row) => {
  return row.id
}
const loading = ref(true)
const rules = ref({
  stackName: [{ required: true, message: '请选择垛位', trigger: ['change', 'input'] }],
  totalCount: [
    { required: true, message: '数量不能为空', trigger: ['change', 'input'] },
    { required: true, validator: reservation, trigger: ['change', 'input'] }
  ],
  totalWeight: [
    { required: true, message: '重量不能为空', trigger: ['change', 'input'] },
    { required: true, validator: reservation, trigger: ['change', 'input'] }
  ],
  truckTime: [
    {
      required: true, message: props.wbOrderType === 'GOODS_OUT' ? '请输入车辆出库日期'
        : '请输入车辆入库日期', trigger: 'blur'
    }
  ],
  carPlateNum: [
    { required: true, message: '请输入车牌号', pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/, trigger: ['change', 'input', 'blur'] },
  ],
  driverPhone: [
    { required: false, message: '请输入正确的手机号', pattern: /^1[356789]\d{9}$/, trigger: ['change', 'input', 'blur'] }
  ],
  idCard: [
    { required: false, message: '请输入正确的身份证号', pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, trigger: ['change', 'input', 'blur'] }
  ],
  vehicleVoucher: [
    { required: true, message: '请上传车辆附件', trigger: ['change', 'input', 'blur'] },
  ],
  wbCode: [
    { required: true, message: '请关联榜单', trigger: ['change', 'input', 'blur'] },
  ],
  isThings: [
    { required: true, message: '请选择物联网', trigger: ['change', 'input', 'blur'] },
  ],
})
const handleFormValidate = (valid, bol) => {
  if (bol !== undefined && bol === false) {
    elFormItemClass.value = { marginBottom: '18px' }
  }
  // else if (bol !== undefined && bol === true) {
  //   elFormItemClass.value = { marginBottom: '0px' }
  // }
}

const elFormItemClass = ref({ marginBottom: '0px' })


// 获取垛位列表
const stackList = ref([])
const checkForm = () => {
  proxy.$refs.formRef.validate((valid) => {
    console.log(valid)
    emit('TableValidate', valid)
  })
}
const formRef = ref({})
// 是否显示司机附件弹窗
const isShowCarFile = ref(false)
const selectItem = ref({})
const updateCodeShow = ref(false)
const qrcodeData = ref()
const stockVisible = ref(false)
const stockData = ref([])
const isShowGoodsFile = ref(false)
const showCarFile = async (item, index) => {
  selectItem.value = item
  selectIndex.value = index
  isShowCarFile.value = true
}
const showGoodsFile = async (item, index) => {
  selectItem.value = item
  selectIndex.value = index
  isShowGoodsFile.value = true
}
const changeVehicleVoucher = (data) => {
  console.log(data)
  selectItem.value.vehicleVoucher = data
  console.log(form.tableData)
  formRef.value.validateField(`tableData[${selectIndex.value}].vehicleVoucher`, () => null);
}
const changeGoods = (data) => {
  console.log('changeGoods', data)
  selectItem.value.wbCertificateFile = data
  console.log(form.tableData)
}
//关闭二维码页面时获取最新的凭证
const getWXUploadFiles = async (apmId) => {
  const { code, data } = await getTruckFile(apmId + '/' + (props.wbOrderType === 'GOODS_IN' ? '1' : '2'))
  if (code === 200) {
    selectItem.value.vehicleVoucher = data
  }
}
const showQrcode = (row) => {
  updateCodeShow.value = true
  selectItem.value = row
  qrcodeData.value = getQrCodeUrl(row.id, props.wbOrderType === 'GOODS_IN' ? '1' : '2')
}
const addRow = (row) => {
  const newRow = JSON.parse(JSON.stringify(row))
  if (props.scene === 'storage') {
    newRow.availableCount = ''
    newRow.availableWeight = ''
    newRow.stackId = ''
    newRow.stackCode = ''
    newRow.stackName = ''
    newRow.totalWeight = ''
    newRow.totalCount = ''
  } else if (props.scene === 'edit') {
    newRow.carPlateNum = ''
    newRow.id = 0
    newRow.vehicleVoucher = ''
    newRow.wbCode = ''
    newRow.snapshotRemark = ''
    newRow.driverPhone = ''
    newRow.idCard = ''
    newRow.driverName = ''
    newRow.wbCertificateFile = ''
  }
  form.tableData.push(newRow)
  nextTick(() => {//解决新生成的垛位没有可选值的问题
    stackList.value = JSON.parse(JSON.stringify(stackList.value))
  })
}

import { getCurrentInstance } from 'vue';
const instance = getCurrentInstance(); // 获取当前组件实例
const confirmAndRefresh = () => {
  console.log("触发自定义事件")
  instance.emit('confirm-and-refresh'); //
}

//盘点
const takeStock = (index, row, productCode, companyCode) => {
  const data = {
    roomId: row.roomId,
    stackId: row.stackId,
    whCode: row.whCode,
    productCode: productCode,
    companyCode: companyCode
  };
  console.log("data", data);
  const { code, rows } = selectCloudByCompanyProductStack(data)
    .then(response => {
      const { code, rows } = response;
      console.log("code", code);
      if (code === 200) {
        stockVisible.value = true
        stockData.value = rows
      }
    })
}

const removeRow = (index, row) => {
  if (form.tableData.length === 1 && (varietyCode.value !== 'CF' || props.scene === 'edit')) {
    ElMessage({
      type: 'warning',
      message: '数据最少为一条'
    })
    return
  }
  if (row.id) {
    ElMessageBox.confirm("确定移除本行数据吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteCurrentRow(row, index)
      })
      .catch(() => { })
  } else {
    deleteCurrentRow(row, index)
  }
}
const deleteCurrentRow = (row, index) => {
  emit('removeIndex', index, row)
  form.tableData.splice(index, 1)
  if (row.wbCode && !form.tableData.find(item => item.wbCode === row.wbCode)) {
    filterWbCode.value.push(row.wbCode)
    filterWbCode.value = [...new Set(filterWbCode.value)];
    console.log("filterWbCode", filterWbCode.value)
  }
  // calculate(row)
}

const filterWbCode = ref([])
const getWeightCount = (row) => {
  const { receiptCount, specificationVal } = row
  if (varietyCode.value !== 'FG') {
    row.totalWeight = futuresLotTon.value * receiptCount
    row.totalCount = (futuresLotTon.value * receiptCount / specificationVal).toFixed(4) * 1
  }
  summation()
}
// 根据 storageBasis 计算数量和重量
const handleInput = (row, wbOrderType, scene, showWeight) => {
  inputChange.value = false
  console.log("wbOrderType", wbOrderType)
  console.log("scene", scene)
  console.log("showWeight", showWeight)
  const { totalWeight, totalCount, specificationVal } = row
  let num = (totalWeight / specificationVal).toFixed(4) * 1
  console.log(num)
  if (Number.isNaN(num)) {
    return
  }

  if (varietyCode.value !== 'FG') {//玻璃不自动计算数量,用户手动输入
    row.totalCount = (totalWeight / specificationVal).toFixed(4) * 1
  }
  row.isNeedValidate = true;
  summation()
}

const oninput = () => {
  inputChange.value = false
  summation()
}

const handleInputCount = (row) => {
  inputChange.value = false
  const { totalCount, specificationVal } = row
  console.log("totalCount", totalCount)
  console.log("specificationVal", specificationVal)
  let num = (totalCount * specificationVal).toFixed(4) * 1
  console.log(num)
  if (Number.isNaN(num)) {
    return
  }
  if (varietyCode.value !== 'FG') {
    row.totalWeight = (totalCount * specificationVal).toFixed(4) * 1
  }
  row.isNeedValidate = true;
  summation()
}


const calculate = (row, wbOrderType, scene, showWeight) => {
  const { totalWeight, totalCount, specificationVal } = row
  console.log("scene", scene)
  if (scene === 'add') {
    return;
  }
  const totalCountNew = new Decimal(totalCount);
  const specificationValNew = new Decimal(specificationVal);
  const totalWeightNew = new Decimal(totalWeight);
  const result = totalCountNew.times(specificationValNew).eq(totalWeightNew);
  console.log("result", result)
  if (!result) {
    const message = '您输入的数量不等于重量/规格，请核查！';
    const confirmButtonText = '知道了';
    const type = 'warning';
    const showCancelButton = false;
    ElMessageBox.confirm(`${message}`, '提示', {
      confirmButtonText,
      type,
      showCancelButton,
    });
  }
}

const summation = () => {
  totalCount.value = 0
  totalWeight.value = 0
  form.tableData.forEach(item => {
    totalCount.value += item?.totalCount ? item.totalCount * 1 : 0
    totalWeight.value += item?.totalWeight ? item.totalWeight * 1 : 0
  })
}
const changeDate = async (row) => {
  // cars.value = []
  // row.carPlateNum = ''
  // row.snapshotRemark = ''
  // row.vehicleVoucher = ''
  // row.wbCertificateFile = ''
  // row.driverName = ''
  // row.driverIdCard = ''
  // row.driverPhone = ''
  row.time = getTime(row.truckTime)
}
// 车牌列表
const cars = ref([])
const snapTimeList = ref([])
const getCar = async (row) => {
  if (!row.truckTime) {
    ElMessage({
      type: 'warning',
      message: '请先选择入库日期'
    });
    return;
  }
  console.log('----', row, props.sourceWay)
  if (row && row.truckTime) {
    const { code, data } = await getCarPictureList(proxy.addDateRange({ isExit: 0, wbCategory: props.sourceWay != '1' ? 1 : 0 }, [getDate(row.truckTime), getDate(row.truckTime) + ' 23:59:59']))
    if (code === 200 && data && data.length >= 1) {
      cars.value = data.filter(item => item.isExit !== 1).map((item) => (
        { ...item, carPlateNum: item.plateNo, time: getTime(item.pictureTime), url: item.pictureUrl, }
      ))
      //cars根据车牌去重
      cars.value = [...new Set(cars.value.map(item => item.carPlateNum))].map(item => cars.value.find(item2 => item2.carPlateNum === item))
      console.log(cars.value)
    } else {
      cars.value = []
    }
  }
}
const getCarTime = async (row) => {
  if (!row || !row.carPlateNum || !row.truckTime) {
    ElMessage({
      type: 'warning',
      message: '请先选择入库日期和车牌号'
    });
    return;
  }
  const { code, data } = await getCarPictureList(proxy.addDateRange({ isExit: 0, wbCategory: props.sourceWay != '1' ? 1 : 0, plateNo: row.carPlateNum }, [getDate(row.truckTime), getDate(row.truckTime) + ' 23:59:59']))
  if (code === 200 && data && data.length >= 1) {
    snapTimeList.value = data.filter(item => item.isExit !== 1).map((item) => (
      { ...item, carPlateNum: item.plateNo, time: getTime(item.pictureTime), url: item.pictureUrl, }
    ))
    console.log(snapTimeList.value)
  } else {
    snapTimeList.value = []
  }
}
const changeTime = async (car, row) => {
  if (typeof car === 'string') {
    row.time = car
    row.truckTime = getDate(row.truckTime) + ' ' + car.time
    return
  }
  row.truckTime = car.pictureTime
  row.time = getTime(car.pictureTime)
}
const getTime = (timeString) => {
  const date = new Date(timeString);
  // 获取小时、分钟和秒  
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
}
const getDate = (dateString, nextDay = 0) => {
  // 将日期字符串转换为日期对象
  const dateObj = new Date(dateString);
  dateObj.setDate(dateObj.getDate() + nextDay);
  // 提取年、月、日
  const year = dateObj.getFullYear().toString();
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，需要加1
  const day = dateObj.getDate().toString().padStart(2, '0');

  // 组合成年月日字符串
  return `${year}-${month}-${day}`;
}
const changeCar = async (car, row) => {
  console.log(car, row)
  row.wbCode = ''
  row.snapshotRemark = ''
  row.isThings = false
  if (typeof car === 'string') {
    //手动输入车牌,获取司机信息
    row.carPlateNum = car
  } else {
    row.carPlateNum = car.carPlateNum
    // row.truckTime = car.pictureTime
  }
  const { code, data } = await queryByNumber({ whCode: row.whCode, carPlateNum: row.carPlateNum })
  if (code === 200) {
    row.driverName = data.driverName
    row.driverPhone = data.driverPhone
    row.idCard = data.idCard
    row.vehicleVoucher = data.vehicleVoucher
    row.wbCertificateFile = data.goodsCertificateFile
    // let resDataArray = data.wbCertificateFile ? data.wbCertificateFile.split(',') : []
    // form.tableData.forEach(item => {
    //   resDataArray = resDataArray.concat(item.wbCertificateFile?.split(','))
    // })
    // emit('changeGoods', resDataArray)
  }
}
const changeStack = (event, row) => {
  const { val, selectedData } = event
  console.log(val, selectedData)
  row.stackCode = selectedData[0].stackCode
  row.stackName = selectedData[0].stackName
  row.stackId = selectedData[0].stackId
  row.roomCode = selectedData[0].roomCode
  row.roomId = selectedData[0].roomId
  row.roomName = selectedData[0].roomName
  row.availableCount = selectedData[0].availableCount
  row.availableWeight = selectedData[0].availableWeight
  row.remainAmount = selectedData[0].remainAmount
}
const batchSize = 10; // 每批校验的数据量
//分批次校验form的rule
const validateBatch = async (startIndex) => {
  const endIndex = Math.min(startIndex + batchSize, form.tableData.length);
  for (let i = startIndex; i < endIndex; i++) {
    const row = form.tableData[i];
    const columns = [
      { name: 'stackName', label: '垛位' },
      { name: 'totalCount', label: '数量' },
      { name: 'totalWeight', label: '重量' },
      { name: 'truckTime', label: props.wbOrderType === 'GOODS_OUT' ? '车辆出库日期' : '车辆入库日期' },
      { name: 'carPlateNum', label: '车牌号' },
      { name: 'vehicleVoucher', label: '车辆附件' },
      { name: 'driverPhone', label: '司机电话' },
      { name: 'idCard', label: '身份证号' },
    ];

    for (const column of columns) {
      const prop = `tableData[${i}].${column.name}`;
      console.log(prop)
      try {
        await formRef.value.validateField(prop);
      } catch (error) {
        ElMessage({
          type: 'warning',
          message: `第 ${i + 1} 行的 ${column.label} 校验失败`
        });
        return; // 停止后续校验
      }
    }
  }
  if (endIndex < form.tableData.length) {
    setTimeout(() => validateBatch(endIndex), 0);
  } else {
    //校验成功,向父组件发送消息
    emit('checkFormBatchOKHander', true)
  }
};

const checkFormBatch = () => {
  validateBatch(0);
};
const getCreateCarInfo = async (carPlateNum, row) => {
  const { code, rows } = await listCompanyTruck({ pageNum: 1, pageSize: 1, carPlateNum })
  if (code === 200 && rows.length > 0) {
    const car = rows[0]
    row.carPlateNum = car.carPlateNum
    row.driverName = car.driverName
    row.driverPhone = car.driverPhone
    row.idCard = car.idCard
    row.vehicleVoucher = car.identityCard
  }
}

const selectedRows = ref([]);

const handleSelectionChange = (selection) => {
  selectedRows.value = selection;
}

// 处理键盘按下事件，限制输入为数字并最多保留四位小数
function handleKeyPress(event) {
  const charCode = (typeof event.which === "undefined") ? event.keyCode : event.which;
  const charStr = String.fromCharCode(charCode);
  const regex = /^\d*\.?\d{0,4}$/;
  if (!regex.test(charStr)) {
    event.preventDefault();
  }
}
function updateProfitLoss(row) {
  if (row.firmOfferWeight !== undefined && row.remainWeight !== undefined && row.remainWeight !== '' && row.firmOfferWeight !== '') {
    row.weight = (parseFloat(row.remainWeight) - parseFloat(row.firmOfferWeight)).toFixed(2);
  } else {
    row.weight = 0;
  }
  if (row.firmOfferCount !== undefined && row.remainCount !== undefined && row.remainCount !== '' && row.firmOfferCount !== '') {
    row.count = (parseFloat(row.remainCount) - parseFloat(row.firmOfferCount)).toFixed(2);
  } else {
    row.count = 0
  }
}
const confirmStockTaking = () => {
  if (selectedRows.value.length === 0) {
    ElMessage({
      type: 'warning',
      message: '请勾选盘库数据'
    });
    return;
  } else {
    const missingWeight = selectedRows.value.some(row => !row.firmOfferWeight);

    if (missingWeight) {
      ElMessage({
        type: 'warning',
        message: '实盘重量不能为空，请填写实盘重量'
      });
      return;
    }
    const count = selectedRows.value.some(row => !row.firmOfferCount);

    if (count) {
      ElMessage({
        type: 'warning',
        message: '实盘数量不能为空，请填写实盘数量'
      });
      return;
    }
    const remark = selectedRows.value.some(row => !row.remark || row.remark.trim() === '');
    if (remark) {
      ElMessage({
        type: 'warning',
        message: '请输入盘库原因'
      });
      return;
    }
    const selectedRowsJson = JSON.stringify(selectedRows.value);
    firmOfferStock(selectedRowsJson)
      .then(response => {
        const { code, msg } = response;
        console.log("code", code)
        if (code === 200) {
          ElMessage({
            type: 'success',
            message: '盘库成功'
          });
          stockVisible.value = false;
          confirmAndRefresh()
        } else {
          ElMessage({
            type: 'error',
            message: msg
          });
        }
      })
  }

};

function multiply(a, b) {
  if (a === 'undefined' || b === 'undefined') {
    return;
  }
  const result = a * b;
  const formattedResult = result.toFixed(4).replace(/\.?0+$/, '');
  return formattedResult;
}

const wbCodeExist = ref(false)
watch(
  () => form.tableData,
  (val) => {
    console.log('val----', val)
    if (val.length > 0) {
      summation()
      // val.forEach(item => {
      //   if (item.wbCode) {
      //     wbCodeExist.value = true
      //   }
      //   if (item.truckTime) {
      //     item.time = getTime(item.truckTime)
      //   }
      // })
    }
    nextTick(() => {
      console.log('渲染完成')
      loading.value = false
    })
  },
  { immediate: true }
)
watch(
  () => props.data,
  (val) => {
    console.log('props.data', val)

  }
)
watch(
  () => props.data,
  (val) => {
    if (val) {
      console.log('props.data2', val)
      form.tableData = val
      summation()
    }
  },
  {
    immediate: true,
    deep: true
  }
)
watch(
  () => props.stacks,
  (val) => {
    // console.log('stacks', val)
    stackList.value = val
  },
  {
    immediate: true,
    deep: true
  }
)
watch(
  () => props.carVisible,
  (val) => {
    // proxy.$refs.formRef?.resetFields()
    // nextTick(() => {
    //   form.tableData = JSON.parse(JSON.stringify(dataeep.value))
    // })
    console.log('carVisible', val)
  },
  {
  }
)
const futuresLotTon = ref()
watch(
  () => store.state.variety.itemObj,
  (itemObj) => {
    if (itemObj) {
      storageBasis.value = itemObj.storageBasis
      weightUnit.value = itemObj.goodsWeightUnit
      countUnit.value = itemObj.goodsCountUnit
      varietyCode.value = itemObj.varietyCode
      futuresLotTon.value = itemObj.futuresLotTon
      console.log(futuresLotTon.value)
      if (props.outType) {//如果手动修改了库容依据,则以手动修改的为准
        storageBasis.value = props.outType
      }
    }
  },
  {
    immediate: true,
  }
)

// watch(() => props.transWay, (val) => {
//   if (val == '5') {
//   rules.value = {
//     stackName: [{ required: true, message: '请选择垛位', trigger: ['change', 'input'] }],
//     totalCount: [
//       { required: true, message: '数量不能为空', trigger: ['change', 'input'] },
//       { required: true, validator: reservation, trigger: ['change', 'input'] }
//     ],
//     totalWeight: [
//       { required: true, message: '重量不能为空', trigger: ['change', 'input'] },
//       { required: true, validator: reservation, trigger: ['change', 'input'] }
//     ],
//     carPlateNum: [
//       { required: false, message: '请输入正确的车牌号', pattern: /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/, trigger: [ 'change', 'input', 'blur'] },
//     ],
//     driverPhone: [
//       { required: false, message: '请输入正确的手机号', pattern: /^1[356789]\d{9}$/, trigger: ['change', 'input', 'blur'] }
//     ],
//     idCard: [
//       { required: false, message: '请输入正确的身份证号', pattern: /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, trigger: ['change', 'input', 'blur'] }
//     ],
//     wbCode: [
//       { required: true, message: '请关联榜单', trigger: ['change', 'input', 'blur'] },
//     ],
//   }
// }
// }, {
//   immediate: true,
//   deep: true
// })

onMounted(() => {
})
defineExpose({
  form,
  formRef,
  totalWeight,
  totalCount,
  checkForm,
  filterWbCode,
  checkFormBatch,
  getTime
})

// 物联网信息
const thingsDialogVisible = ref(false)
//选择抓拍
const chooseSnapshotVisible = ref(false)
const inFileRef = ref()
const outFileRef = ref()
const snapshotForm = reactive({
  inFileList: [],
  outFileList: [],
  snapshotRemark: '',
})
const chooseSnapshot = async (row) => {
  selectItem.value = row
  snapshotForm.inFileList = []
  snapshotForm.outFileList = []
  snapshotForm.snapshotRemark = row.snapshotRemark ? row.snapshotRemark : ''
  if (!row.carPlateNum || !row.truckTime) {
    ElMessage({
      type: 'warning',
      message: '请先选择车牌和日期'
    })
    return
  }
  chooseSnapshotVisible.value = true
  const { code, msg, data } = await getCarPictureList(proxy.addDateRange({ plateNo: row.carPlateNum }, [getDate(row.truckTime) || getDate(row.createTime), getDate(row.truckTime) + ' 23:59:59' || getDate(row.createTime) + ' 23:59:59']))
  if (code === 200) {
    const isExit1url = []
    const isExit2url = []
    if (data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.pictureUrl) {
          if (element.isExit !== 1) {
            isExit1url.push({ url: element.pictureUrl, time: element.pictureTime, isCheck: row.carPictureIds?.includes(element.id), id: element.id })
          } else {
            isExit2url.push({ url: element.pictureUrl, time: element.pictureTime, isCheck: row.carPictureIds?.includes(element.id), id: element.id })
          }
        }
      }
      snapshotForm.inFileList = isExit1url
      snapshotForm.outFileList = isExit2url
    }
  }
}
const uploadHandler = () => {
  const inlist = inFileRef.value.fileList?.filter(item => { return item.link && item.isCheck }).map(item => { return item.link.id })
  const outlist = outFileRef.value.fileList?.filter(item => { return item.link && item.isCheck }).map(item => { return item.link.id })
  if ((!inlist || inlist.length === 0 || !outlist || outlist.length === 0) && !snapshotForm.snapshotRemark) {
    ElMessage({
      type: 'warning',
      message: '请勾选入场和出场抓拍,或者填写抓拍丢失备注'
    })
    return
  }
  selectItem.value.isThings = true
  chooseSnapshotVisible.value = false
  console.log('selectItem', inlist, outlist, inFileRef.value.fileList)
  selectItem.value.snapshotRemark = snapshotForm.snapshotRemark
  selectItem.value.carPictureIds = (inlist ? inlist : []).concat(outlist ? outlist : [])
  console.log('selectItem', selectItem)
  console.log('selectItem', form.tableData)
}
const showNetwork = async (row) => {
  selectItem.value = row
  if (!row.truckTime || !row.carPlateNum) {
    ElMessage({
      type: 'warning',
      message: '请先选择时间和车牌'
    })
    return
  }
  await getThing(row)
  console.log('selectItem', selectItem.value.thingsInfo)
  if (selectItem.value.thingsInfo) {
    thingsDialogVisible.value = true
  } else {
    ElMessage({
      type: 'warning',
      message: '暂无信息'
    })
  }
}
const getThing = async (row) => {
  console.log('getThing', row)
  const params = {
    plateNo: row.carPlateNum,
  }
  if (row.isThings) {
    params.params = [getDate(row.truckTime, -1) || getDate(row.createTime, -1), getDate(row.truckTime, 2) || getDate(row.createTime, 2)]
  } else {
    params.inTime = row.truckTime || row.createTime
    params.wbCode = row.wbCode ? row.wbCode : null
  }
  const { code, msg, data } = await getCarPictureList(params)
  if (code === 200) {
    const isExit1url = []
    const isExit2url = []
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.pictureUrl && ((row.isThings && row.carPictureIds?.includes(element.id)) || !row.isThings)) {
          if (element.isExit !== 1) {
            isExit1url.push(element.pictureUrl)
          } else {
            isExit2url.push(element.pictureUrl)
          }
        }
      }
      row.thingsInfo = {
        operations: [{
          images: [{
            title: `${row.carPlateNum}抓拍`,
            needTransfer: true,
            url: isExit1url.length > 0 ? isExit1url.join(',') : '',
            isVideo: false
          }],
        }, {
          images: [{
            isExit: 1,
            title: `${row.carPlateNum}抓拍`,
            needTransfer: true,
            url: isExit2url.length > 0 ? isExit2url.join(',') : '',
            isVideo: false
          }],
        }],
        snapshotRemark: row.snapshotRemark ? row.snapshotRemark : null
      }
    }
    console.log('row', row.thingsInfo)
  }
}
const getNextDaySnapshot = async (dayCount) => {
  if (dayCount === -1) {
    snapshotForm.inFileList = []
  } else if (dayCount === 1) {
    snapshotForm.outFileList = []
  }

  const { code, msg, data } = await getCarPictureList(proxy.addDateRange({ plateNo: selectItem.value.carPlateNum },
    [getDate(selectItem.value.truckTime, dayCount) || getDate(selectItem.value.createTime, dayCount), getDate(selectItem.value.truckTime, dayCount) + ' 23:59:59' || getDate(selectItem.value.createTime, dayCount) + ' 23:59:59']))
  if (code === 200) {
    const isExit1url = []
    const isExit2url = []
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element.pictureUrl) {
          if (element.isExit !== 1 && dayCount === -1) {
            isExit1url.push({ url: element.pictureUrl, time: element.pictureTime, isCheck: selectItem.value.carPictureIds?.includes(element.id), id: element.id })
          } else {
            isExit2url.push({ url: element.pictureUrl, time: element.pictureTime, isCheck: selectItem.value.carPictureIds?.includes(element.id), id: element.id })
          }
        }
      }
      if (dayCount === 1) {
        console.log('isExit2url', isExit2url)
        snapshotForm.outFileList = isExit2url
      } else if (dayCount === -1) {
        snapshotForm.inFileList = isExit1url
      }
    } else {
      ElMessage({
        type: 'warning',
        message: '暂无抓拍数据'
      })
    }
  }
}
const weightVisible = ref(false)
const selectIndex = ref()
const relevanceWB = async (row, index) => {
  selectItem.value = row
  selectIndex.value = index
  weightVisible.value = true
}
const sureSelectWB = (row) => {
  console.log(row)
  // 毛重: grossWeight、皮重:tareWeight 扣重: deductWeight 毛重-皮重-扣重=实际重量
  const { wbCode, realWeight, goodsCount } = row
  selectItem.value.totalWeight = realWeight
  if (goodsCount && goodsCount != 0) {
    selectItem.value.totalCount = goodsCount
  } else {
    selectItem.value.totalCount = (realWeight / selectItem.value.specificationVal).toFixed(4)
  }
  selectItem.value.wbCode = wbCode
  console.log(selectItem.value)
  weightVisible.value = false
  formRef.value.validateField(`tableData[${selectIndex.value}].wbCode`, () => null);
}
</script>
<style lang="scss" scoped>
.statistic {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  padding: 0 20px;
  height: 45px;
  font-size: 16px;
  color: rgba(0, 0, 0, .85);
  font-weight: 650;
  //   box-shadow: 6px 6px 12px 6px rgba(0,0,0,0.08);
  background-color: #fff;

  &-item {
    .count {
      color: #337FFF;
      font-size: 18px;
    }
  }
}

:deep(.el-form-item__content) {
  margin-left: 0 !important;
}

.el-upload-list--picture-card .el-upload-list__item,
.el-upload--picture-card {
  width: 50px !important;
  height: 50px !important;
}


/* 设置 el-table 行高 */
.el-table .el-table__body-wrapper tbody>tr>td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

/* 设置 el-table-header-cell 行高 */
.el-table .el-table__header-wrapper thead>tr>th {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}



.el-upload-list__item-preview {
  display: none !important;
}

.el-upload-list--picture-card .el-upload-list__item-actions span+span {
  margin-left: 0 !important;
}

.setred::before {
  content: "*";
  color: red;
  font-weight: bold;
  font-size: 16px;
  padding-right: 5px;
}
</style>