import { onBeforeUnmount, onMounted, ref } from 'vue';

export function useResize(cb) {
  onMounted(() => {
    cb();
    window.addEventListener('resize', cb);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', cb);
  });
}

export function useSetInterval(cb, interval = 100) {
  let timer = null;
  onMounted(() => {
    cb();
    timer = setInterval(cb, interval);
  });

  onBeforeUnmount(() => {
    if (timer) clearInterval(timer);
  });
}

export function useAnimate(cb, time, done = () => { }, delay = 0) {
  let t = 0;
  let lastTime;
  let timerId;
  let isRunning = false;
  let paused = false;
  let allTime = time + delay;

  function animate() {
    if (!isRunning || paused) {
      timerId = requestAnimationFrame(animate);
      return;
    }
    let now = performance.now();
    let dt = now - lastTime;
    t += dt;
    lastTime = now;
    if (t > delay) {
      cb(Math.min((t - delay) / time, 1));
    }
    if (t >= allTime) {
      isRunning = false;
      cancelAnimationFrame(timerId);
      done();
      return;
    }
    timerId = requestAnimationFrame(animate);
  }

  function setTime(t) {
    time = t;
    allTime = time + delay;
  }

  function setDelay(d) {
    delay = d;
    allTime = time + delay;
  }

  function start() {
    t = 0;
    isRunning = true;
    lastTime = performance.now();
    if (timerId) cancelAnimationFrame(timerId);
    timerId = requestAnimationFrame(animate);
  }

  function stop() {
    if (!isRunning) return;

    t = 0;
    isRunning = true;
    paused = false;
    if (timerId) cancelAnimationFrame(timerId);
  }

  function pause() {
    if (!isRunning) return;
    paused = true;
  }

  function resume() {
    if (!isRunning || !paused) return;
    paused = false;
    lastTime = performance.now();
  }

  return {
    start,
    stop,
    pause,
    resume,
    setTime,
    setDelay
  };
}
function debounce(cb, time = 100) {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(cb, time);
  };
}
/**
 *
 * @param {重置后的视图宽高回调} cb
 * @returns
 */
export function useCalcView(cb) {
  const viewWidth = ref('100vw');
  const viewHeight = ref('100vh');
  const viewTransform = ref('');
  const viewTransformX = ref('')
  const viewTransformX2 = ref('')
  const viewTransformY = ref('')
  function getPercent(s) {
    return (0.5 * (1 - s)) / s;
  }

  const calcView = debounce(() => {
    const sw = window.innerWidth / 1920;
    const sh = window.innerHeight / 1080;
    let w,
      h,
      s = 1;
    if (sw >= 1 && sh >= 1) {
      viewWidth.value = '100vw';
      viewHeight.value = '100vh';
      viewTransform.value = '';
      w = window.innerWidth;
      h = innerHeight;
      viewTransformY.value = `scale(${sw}) translateY(${-h * getPercent(sw)
        }px)`
    } else if (sw >= 1 && sh < 1) {
      w = (1080 * window.innerWidth) / window.innerHeight;
      h = 1080;
      s = sh;
      viewWidth.value = `${w}px`;
      viewHeight.value = '1080px';
      viewTransform.value = `scale(${sh}) translateX(${-w * getPercent(sh)
        }px) translateY(${-1080 * getPercent(sh)}px)`;
    } else if (sw < 1 && sh >= 1) {
      h = (1920 * window.innerHeight) / window.innerWidth;
      w = 1920;
      s = sw;
      viewHeight.value = `${h}px`;
      viewWidth.value = '1920px';
      viewTransform.value = `scale(${sw}) translateX(${-1920 * getPercent(sw)
        }px) translateY(${-h * getPercent(sw)}px)`;
      viewTransformY.value = `scale(${sw}) translateY(${-h * getPercent(sw)
        }px)`
    } else {
      s = Math.min(sw, sh);
      (w = 1920), (h = 1080);
      if (sw > sh) {
        w = (1080 * window.innerWidth) / window.innerHeight;
      } else {
        h = (1920 * window.innerHeight) / window.innerWidth;
      }
      viewHeight.value = `${h}px`;
      viewWidth.value = `${w}px`;
      viewTransform.value = `scale(${s}) translateX(${-w * getPercent(s)
        }px) translateY(${-h * getPercent(s)}px)`;
      viewTransformY.value = `scale(${s}) translateY(${-h * getPercent(s)}px)`
    }
    let index = viewTransform.value.indexOf('translateY(');
    if (index !== -1) {
      viewTransformX.value = viewTransform.value.substring(0, index) + `translateY(${-60 * getPercent(s)}px)`;
      viewTransformX2.value = viewTransform.value.substring(0, index) + `translateY(${60 * getPercent(s)}px)`;
    } else {
      viewTransformX.value = ''
      viewTransformX2.value = ''
    }

    console.log('viewTransform', viewTransform.value)
    console.log('viewTransformX', viewTransformX.value)
    console.log('viewTransformX2', viewTransformX2.value)
    console.log('viewTransformY', viewTransformY.value)
    if (cb) cb(w, h, s, viewWidth.value, viewHeight.value, viewTransform.value, viewTransformX.value, viewTransformY.value);
  }, 500);

  onMounted(() => {
    calcView();
    window.addEventListener('resize', calcView);
  });

  onBeforeUnmount(() => {
    console.log('unmounted')
    window.removeEventListener('resize', calcView);
  });

  return {
    viewWidth,
    viewHeight,
    viewTransform,
    viewTransformX,
    viewTransformY,
    viewTransformX2
  };
}



export function formatOptionCountry(data, mapName) {

  return ({
    tooltip: {
      targer: 'item',
      show: true
    },
    visualMap: [
      {
        seriesIndex: '0',
        realtime: false,
        calculable: true,
        type: 'piecewise',
        show: false,
        inRange: {
          color: ['#008CCF', '#00FFFF']
        },
        pieces: [{ gte: 1 }, { gte: 0, lte: 1 }]
      }
    ],
    geo: {
      map: mapName ? mapName : 'china',
      animationDurationUpdate: 0, //实现缩放、拖动同步且不卡顿
      roam: true,
      // scaleLimit: { // 滚轮缩放的极限控制，默认的缩放为1
      //     min: 1,  // 最小的缩放值
      //     max: 8,  // 最大的缩放值
      // },
      zlevel: 1,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zoom: 0,
      boundingCoords: [
        [-180, 90],
        [180, -90]
      ],
      //设置地图区域的样式
      itemStyle: {
        areaColor: '#008CCF',
        borderColor: '#74B2C0',
        borderWidth: '2',
        color: '#fff'
      },
      //高亮的时候地图区域
      emphasis: {
        itemStyle: {
          areaColor: '#00FFFF',
          color: '#fff',
          //设置阴影
          shadowColor: '#6AA9C3',
          shadowOffsetX: -5,
          shadowOffsetY: 5,
          shadowBlur: 10
        }
      },
      label: {
        show: true,
        // normal: {
        fontSize: '14',
        color: '#C0C4CC',
        position: 'inside',
        backgroundColor: 'rgba(0, 58, 149, 0.6)',
        padding: [4, 5],
        borderRadius: 3,
        borderWidth: 1,
        borderColor: 'rgba(0, 58, 149,1)',
        formatter: function (params) {
          let formatData =
            data.filter((item) => item.name === params.name) || [];
          if (formatData.length > 0) {
            return params.name + ' ' + formatData[0].value;
          } else {
            return params.name;
          }
        }
        // },
      }
    },
    series: [
      {
        type: 'map',
        map: mapName,
        // layoutCenter: ['50%', '50%'],
        // layoutSize: '100%',
        roam: true,
        geoIndex: 0,
        label: {
          normal: {
            //静态的时候展示样式
            show: true, //是否显示地图省份得名称
            textStyle: {
              color: '#fff',
              fontSize: 12
            }
          },
          emphasis: {
            //动态展示的样式
            color: '#fff'
          }
        },
        itemStyle: {
          normal: {
            areaColor: '#008CCF'
          },
          emphasis: {
            areaColor: '#00FFFF'
          }
        },
        tooltip: {
          targer: 'item',
          axisPointer: {
            type: ''
          },
          enterable: true,
          borderColor: 'none',
          borderWidth: '0',
          backgroundColor: 'rgba(0,0,0,0)',
          padding: 0,
          formatter: (params) => {
            let formatData =
              data.filter((item) => item.name === params.name) || [];

            if (formatData.length > 0) {
              return `<div style="padding:8px 7px;display:inline-flex;flex-direction: column;justify-content:center;align-items:center;background:rgba(0, 58, 149, 0.6);border-radius:5px;">
                    <div style="color:#00FFFF"><span style="display:inline-flex;width:10px;height:10px;border-radius:50%;background:#00FFFF;margin-right:4px;"></span>${params.name}</div>
                    <div style="color:#00FFFF;font-weight:600;font-size:18px;margin-top:8px;">${formatData[0].value}</div>
                  <div>`;
            } else {
              return `<div style="padding:10px 7px;display:inline-flex;flex-direction: column;justify-content:center;align-items:center;background:rgba(0, 58, 149, 0.6);border-radius:5px;">
                    <div style="color:#00FFFF"><span style="display:inline-flex;width:10px;height:10px;border-radius:50%;background:#00FFFF;margin-right:4px;"></span>${params.name}</div>
                  <div>`;
            }
          }
        },
        animationDurationUpdate: 0, //实现缩放、拖动同步且不卡顿
        zlevel: 1
      },
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        showLegendSymbol: false,
        roam: true,
        itemStyle: {
          areaColor: '#008CCF'
        },
        emphasis: {
          itemStyle: {
            areaColor: '#00FFFF'
          }
        },
        symbolSize: '0',
        tooltip: {
          show: false
        },
        zlevel: 0,
        animationDurationUpdate: 0,
        data: data
      },
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        geoIndex: 0,
        data: [],
        tooltip: {
          show: true,
          formatter: function (params) {
            // console.log('params',params)
            return params.data.name;
          }
        }
      }
    ]
  });
}


export function formatOptionProvince(data, mapName) {
  return ({
    tooltip: {
      targer: 'item',
      show: true
    },
    visualMap: [
      {
        seriesIndex: '0',
        realtime: false,
        calculable: true,
        type: 'piecewise',
        show: false,
        inRange: {
          color: ['#008CCF', '#00FFFF']
        },
        pieces: [{ gte: 1 }, { gte: 0, lte: 1 }]
      }
    ],
    geo: {
      map: mapName ? mapName : 'china',
      animationDurationUpdate: 0, //实现缩放、拖动同步且不卡顿
      roam: true,
      // scaleLimit: { // 滚轮缩放的极限控制，默认的缩放为1
      //     min: 1,  // 最小的缩放值
      //     max: 8,  // 最大的缩放值
      // },
      zlevel: 1,
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      zoom: 0,
      boundingCoords: [
        [-180, 90],
        [180, -90]
      ],
      //设置地图区域的样式
      itemStyle: {
        areaColor: '#008CCF',
        borderColor: '#74B2C0',
        borderWidth: '2',
        color: '#fff'
      },
      //高亮的时候地图区域
      emphasis: {
        itemStyle: {
          areaColor: '#00FFFF',
          color: '#fff',
          //设置阴影
          shadowColor: '#6AA9C3',
          shadowOffsetX: -5,
          shadowOffsetY: 5,
          shadowBlur: 10
        }
      },
      label: {
        show: false,
        // normal: {
        fontSize: '14',
        color: '#C0C4CC',
        position: 'inside',
        backgroundColor: 'rgba(0, 58, 149, 0.6)',
        padding: [4, 5],
        borderRadius: 3,
        borderWidth: 1,
        borderColor: 'rgba(0, 58, 149,1)',
        formatter: function (params) {
          let formatData =
            data.filter((item) => item.name === params.name) || [];
          if (formatData.length > 0) {
            return params.name + ' ' + formatData[0].value;
          } else {
            return params.name;
          }
        }
        // },
      }
    },
    series: [
      {
        type: 'map',
        map: mapName,
        // layoutCenter: ['50%', '50%'],
        // layoutSize: '100%',
        roam: true,
        geoIndex: 0,
        label: {
          normal: {
            //静态的时候展示样式
            show: true, //是否显示地图省份得名称
            textStyle: {
              color: '#fff',
              fontSize: 12
            }
          },
          emphasis: {
            //动态展示的样式
            color: '#fff'
          }
        },
        itemStyle: {
          normal: {
            areaColor: '#008CCF'
          },
          emphasis: {
            areaColor: '#00FFFF'
          }
        },
        tooltip: {
          targer: 'item',
          axisPointer: {
            type: ''
          },
          enterable: true,
          borderColor: 'none',
          borderWidth: '0',
          backgroundColor: 'rgba(0,0,0,0)',
          padding: 0,
          formatter: (params) => {
            let formatData =
              data.filter((item) => item.name === params.name) || [];

            if (formatData.length > 0) {
              return `<div style="padding:8px 7px;display:inline-flex;flex-direction: column;justify-content:center;align-items:center;background:rgba(0, 58, 149, 0.6);border-radius:5px;">
                    <div style="color:#00FFFF"><span style="display:inline-flex;width:10px;height:10px;border-radius:50%;background:#00FFFF;margin-right:4px;"></span>${params.name}</div>
                    <div style="color:#00FFFF;font-weight:600;font-size:18px;margin-top:8px;">${formatData[0].value}</div>
                  <div>`;
            } else {
              return `<div style="padding:10px 7px;display:inline-flex;flex-direction: column;justify-content:center;align-items:center;background:rgba(0, 58, 149, 0.6);border-radius:5px;">
                    <div style="color:#00FFFF"><span style="display:inline-flex;width:10px;height:10px;border-radius:50%;background:#00FFFF;margin-right:4px;"></span>${params.name}</div>
                  <div>`;
            }
          }
        },
        animationDurationUpdate: 0, //实现缩放、拖动同步且不卡顿
        zlevel: 1
      },
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        showLegendSymbol: false,
        roam: true,
        itemStyle: {
          areaColor: '#008CCF'
        },
        emphasis: {
          itemStyle: {
            areaColor: '#00FFFF'
          }
        },
        symbolSize: '0',
        tooltip: {
          show: false
        },
        zlevel: 0,
        animationDurationUpdate: 0,
        data: data
      },
      {
        type: 'effectScatter',
        coordinateSystem: 'geo',
        geoIndex: 0,
        data: [],
        tooltip: {
          show: true,
          formatter: function (params) {
            // console.log('params',params)
            return params.data.name;
          }
        }
      }
    ]
  });
}
