<template>
  <el-dialog title="上传凭证" v-model="isShow" :close-on-click-modal="false" width="700px" append-to-body destroy-on-close
    :before-close="close">
    <div>
      <p style="color: #000">
        {{ '请上传图片大小不超过10M，格式为jpg/jpeg/png/pdf格式的凭证。' + (field == 'vehicleVoucher' ? '(司机身份证、司机驾驶证、出库单或入库单)' : '') }}
      </p>
      <el-form ref="addCarFile" :model="carForm">
        <el-form-item :label="field == 'vehicleVoucher' ? '车辆附件' : field == 'wbCertificateFile' ? '磅单凭证' : '质检证书'"
          prop="vehicleVoucher">
          <UploadFile ref="carFileRef" v-model="carForm[props.field]" :multiple="true" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button type="primary" @click="sure">确定</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from "vue";
import { saveCarFile, updateOrderStackWbCertificate } from "@/api/wms/order/inOrder";
import { updateTruckFilePc } from '@/api/wms/appointment/outAppointment'
import { ElMessage } from "element-plus";
const props = defineProps({
  isShow: {
    type: Boolean,
    default: false,
  },
  vehicleVoucher: {
    type: String,
    default: '',
  },
  truckId: {
    type: String,
    default: '',
  },
  orderType: {
    type: String,
    default: "1",
  },
  flag: {
    type: String,
    default: 'saveCarFile'
  },
  isSaveFile: {
    type: Boolean,
    default: true
  },
  field: {
    type: String,
    default: 'vehicleVoucher'
  }
});
const emit = defineEmits(["update:isShow", "sure", "changeVv"]);
const actions = {
  'saveCarFile': saveCarFile,
  'updateTruckFilePc': updateTruckFilePc,
  'updateOrderStackWbCertificate': updateOrderStackWbCertificate
}
const isShow = computed({
  get: () => props.isShow,
  set: (value) => emit("update:isShow", value),
})
const addCarFile = ref();
const carFileRef = ref();
const carForm = reactive({
  vehicleVoucher: "",
  truckFile: "",
  id: "",
  type: "1"//1:入库，2：出库
});

watch(
  () => props.isShow,
  (val) => {
    if (val) {
      carForm[props.field] = props.vehicleVoucher
      carForm.type = props.orderType
    }
  }
);

const sure = async () => {
  carForm[props.field] = carFileRef.value.fileList
    .map((item) => {
      return item.link;
    })
    .join(",") || '';
  if (!carForm[props.field]) {
    ElMessage({
      type: "warning",
      message: "请上传附件",
    });
    return;
  }
  console.log(props.isSaveFile)
  if (!props.isSaveFile) {
    emit("changeVv", carForm[props.field])
    isShow.value = false
    return
  }
  carForm.id = props.truckId
  carForm.truckFile = carForm[props.field]
  const { code, msg } = await actions[props.flag](carForm);
  if (code === 200) {
    ElMessage({
      type: "success",
      message: '保存成功',
    });
    isShow.value = false
    emit("changeVv", carForm[props.field])
  }
};
function close() {
  isShow.value = false
}
</script>
