<template>
  <el-image fit="fill" :src="realSrc" :style="`width:${width}px;height:${height}px;`" :preview-src-list="realSrcList" preview-teleported>
    <template #error>
      <div class="image-slot">
        <span style="font-size: 18px;font-weight: 700;">暂无图片</span>
      </div>
    </template>
  </el-image>
  <div style="text-align: center;margin-top: 20px;" v-if="showDown">
    <el-button type="primary" @click="download">下载</el-button>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { getFileUrl } from "@/api/wms/file";

const props = defineProps({
  src: {
    type: String,
    default: ""
  },
  width: {
    type: [Number, String],
    default: ""
  },
  height: {
    type: [Number, String],
    default: ""
  },
  baseUrl: {
    type: Boolean,
    default: false
  },
  showDown: {
    type: Boolean,
    default: false
  }
});

const realSrc = ref('');
const realSrcList = ref([]);

const getImagesUrl = async (url) => {
  const res = await getFileUrl(url);
  return res.data;
};

const download = async () => {
  console.log("realSrc", realSrc.value);
  const response = await fetch(realSrc.value);
  // 获取资源的数据Blob
  const blob = await response.blob();
  // 创建一个Blob URL
  const blobUrl = window.URL.createObjectURL(blob);
  // 创建a标签并设置属性
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = props.src.split('/').pop() || 'file';;
  // 将a标签添加到文档中
  document.body.appendChild(link);
  // 触发点击事件进行下载
  link.click();
  // 清除创建的Blob URL，以释放内存
  window.URL.revokeObjectURL(blobUrl);
  // 从DOM中移除a标签
  link.remove();
};

watch(() => props.src, async (newSrc) => {
  let real_src_list = newSrc.split(",").filter(item => item && item.trim());
  let srcList = [];
  for (const item of real_src_list) {
    if (props.baseUrl) {
      const url = await getImagesUrl(item);
      srcList.push(url);
    } else {
      srcList.push(item);
    }
  }
  realSrcList.value = srcList;
  realSrc.value = srcList[0]
}, { immediate: true });
</script>

<style lang="scss" scoped>
.el-image {
  border-radius: 5px;
  background-color: #ebeef5;
  box-shadow: 0 0 5px 1px #ccc;

  :deep(.el-image__inner) {
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      transform: scale(1.2);
    }
  }

  :deep(.image-slot) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #909399;
    font-size: 30px;
  }
}
</style>